@import './mixins.scss';

body {
  > .dropdown {
    z-index: 11111 !important;
  }
}

.main-wrapper {
  display: flex;

  .main-board {
    width: 72%;
    padding-right: 20px;
  }

  .main-sidebar {
    width: 28%;
    padding-left: 20px;
  }
}

.btn-custom-step {
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding-top: 9px;
  padding-bottom: 9px;
  min-width: 117px;
  margin-right: 27px;

  > span {
    margin-right: 10px;
  }

  &.btn-icon-left {
    > span {
      margin-right: 0;
      margin-left: 10px;
    }
  }

  &-gray {
    color: #90919D !important;
    border: 1px solid rgba(38, 38, 40, 0.1) !important;

    &:hover {
      background-color: rgba(38, 38, 40, 0.1) !important;
    }
  }
}

.btn-primary {
  &:disabled {
    background: #90919D !important;
    border-color: #90919D !important;
  }
}

.btn-ctas {
  display: flex;
  align-items: center;
}

.round-checkbox {
  display: flex;
  align-items: center;
  overflow: hidden;
  margin-bottom: 0;
  cursor: pointer;

  .check-text {
    color: #262628;
    font-family: Roboto, sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 22px;
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: none;

    + .check-icon {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      font-size: 0;
      margin-right: 10px;
      @include flex-center();
      border: 1px solid rgba(38, 38, 40, 0.4) !important;

      > div {
        display: none;
      }
    }

    &:checked {
      + .check-icon {
        background-color: #58C7C9;
        border: none !important;

        > div {
          display: block;
        }
      }
    }
  }
}

.night-theme {
  .round-checkbox {
    .check-text  {
      color: $white;
    }

    input[type='checkbox'] + .check-icon,
    input[type='radio'] + .check-icon {
      border-color: $white !important;
    }
  }
}

.ss-custom-dropdown {
  padding: 20px 20px 20px 10px;
  border-radius: 6px;
  position: relative;
  background-color: #FFFFFF;

  &-disabled {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.1);
  }

  @media #{$sm} {
    padding-right: 10px;
  }

  .type-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    label {
      color: #262628;
      font-family: Roboto, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
      opacity: 0.4000000059604645;
      margin-right: 10px;
      margin-bottom: 0;
    }

    span {
      color: #262628;
      font-family: Roboto, sans-serif;
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
    }
  }

  .dropdown-menu {
    right: auto;
    left: 100px;
    top: 50px;
    min-width: 200px;
    display: block;
    max-height: 250px;
    overflow: auto;

    .dropdown-item {
      p {
        margin: 0;
      }
    }
  }
}

.ss-switch-holder {
  display: flex;
  align-items: center;

  .ss-label {
    font-size: 12px;
    line-height: 14px;
    color: #90919D;
    margin-right: 18px;
  }

  .ss-custom-switch {
    margin-bottom: 0;
  }
}

.ss-custom-switch {
  position: relative;
  display: inline-block;
  width: 51px;
  height: 30px;
  border-radius: 15px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .ss-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #989AAC;
    border-radius: 15px;
    -webkit-transition: .4s;
    transition: .4s;
    border: 1px solid rgba(0, 0, 0, 0.1);

    &:before {
      position: absolute;
      content: "";
      height: 28px;
      width: 28px;
      border-radius: 50%;
      left: 0px;
      bottom: 0px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.05), 0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 3px 1px 0 rgba(0, 0, 0, 0.05);
    }
  }

  input:checked + .ss-slider {
    background-color: #58C7C9;
    border-color: #58C7C9;
  }

  input:disabled:not(.no-disable-color) + .ss-slider {
    background-color: #9E9E9E;
    border-color: #9E9E9E;
  }

  input:checked + .ss-slider:before {
    transform: translateX(21px);
  }
}

// sticky nav
app-menu-main {
  @media #{$lg-min} {
    display: block;
   // height: 66px;

    .desktop {
      //position: fixed;
      z-index: 301; //prevents recording player going over the fixed menu
      width: 100%;
    }
  }
}

.ss-tabs {
  display: flex;
  align-items: center;

  &-item {
    font-size: 12px;
    line-height: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: bold;
    letter-spacing: 1px;
    padding: 10px 12px;
    position: relative;
    cursor: pointer;

    &.active {
      &:before {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        height: 3px;
        left: 10px;
        right: 10px;
        border-radius: 2px;
        background: #58C7C9;
      }
    }

    &:not(.active) {
      color: #90919D;
    }
  }
}

.ss-song-tag {
  font-size: 8px;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  line-height: 1;
  border: 1px solid;
  border-radius: 3px;
  padding: 0 1px;
  margin-right: 5px;
  letter-spacing: 0.2px;
  position: relative;
  top: -3px;

  &--cs {
    border-color: #084b8a;
    color: #084b8a;
  }

  &--duet {
    border-color: #58c7c5;
    color: #58c7c5;
  }
}

.user-snapicon-carousel {
  .carousel {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.2);
  }

  .carousel-inner {
   // overflow: visible; rem snapicon from popping out
    width: 100%;
    height: 100%;
  }

  .carousel-item {
    width: 100%;
    height: 100%;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding: 3px;
    opacity: 0;
    transition: opacity 0.0s ease;  // rem snapicon from popping out changed from 0.3s
    z-index: -1;
    position: absolute;

    img {
      max-width: 100% !important;
      height: auto;
    }
  }

  .carousel-item.active {
    opacity: 1;
    z-index: 10;
    transition: opacity 0.5s ease 0.5s;
  }

  .carousel-control-prev {
    z-index: 20;
    display: none;
  }


  .carousel-control-next {
    z-index: 20;
    display: none;
  }

  .carousel-indicators {
    display: none;
  }
}

.snappenin-carousel {
  ngu-carousel {
    position: relative;
    padding: 10px;

    &:before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 180px;
      border-radius: 5px;
      background: #c2f4f4;
    }
  }
  &.ngucarousel-custom {
    .ngucarousel {
      > .ngucarousel-inner {
        > .ngucarousel-items > .item {
          flex: 0 0 190px !important;
        }
      }
    }
  }
}

.ss-form-dropdown {
  height: 65px;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
  align-items: center;
  padding: 10px 30px 10px 10px;

  > label {
    font-family: $roboto;
    font-size: 13px;
    opacity: 0.4;
    margin: 0;
  }

  &-block {
    .dropdown-toggle {
      padding: 0;
      font-size: 13px;
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: none !important;

      &:after {
        border-top-width: 6px;
        border-right-width: 6px;
        border-left-width: 6px;
      }
    }

    .dropdown-menu {
      max-height: 300px;
      overflow: auto;
    }
  }
}

.ss-form-row {
  display: grid;
  grid-gap: 30px;

  &-2 {
    grid-template-columns: 1fr 1fr;
  }
}

.ss-check-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #222222;
  opacity: 0.4;
  width: 20px;
  height: 20px;
  border-radius: 50%;

  &.active, &:hover {
    opacity: 1;
    background: #58C7C9;
    border-color: #58C7C9;
  }
}

.ss-snap-credit-icon {
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    border-radius: 50%;
  }
}