/* You can add global styles to this file, and also import other style files */
/* import sass variables */
@import 'vars';
@import "styles/_variables";
@import "styles/mixins";
@import "styles/custom";
@import "styles/session";
@import "styles/subscription";
@import "styles/animation";
@import "styles/payments";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";
//import responsive
@import 'sass/responsive';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

$theme-colors: () !default;
$theme-colors: map-merge((
    "green": $green
), $theme-colors);

@import '~ngx-toastr/toastr.css';
@import "node_modules/bootstrap/scss/bootstrap";
@import "~quill/dist/quill.snow.css";
@import "app/shared/comments/ss-editor/modules/quill-mention/quill.mention.css";
@import "app/shared/comments/ss-editor/modules/quill-emoji/scss/quill-emoji";

@include gridResponsiveByNumber($clsSufix: -ss);

@font-face {
    font-family: 'Mosk_Bold_700';
    src: url('assets/fonts/mosk-bold-700-webfont.woff') format('woff'),
    url('assets/fonts/mosk-bold-700-webfont.ttf') format('truetype');
}
@font-face {
    font-family: 'Carbon Block';
    src: url('assets/fonts/CarbonBlock.woff') format('woff'),
    url('assets/fonts/CarbonBlock.ttf') format('truetype');
}

@import url('https://fonts.googleapis.com/css?family=Montserrat:200,400,500,700');
@import url('https://fonts.googleapis.com/css?family=Poppins:400,600,700|Roboto:100,300,200,400,400i,500,600,700');
@import "./styles/common";
@import "./styles/modal";
@import "./styles/ngucarousel";
html, body {
    height: 100%;
}
a, a:hover{
    color: unset;
  }
  a:hover, a:visited, a:link {
    text-decoration: unset;
    color: unset;
  }
.mention {
    background: none;
}
.ql-editor-styles p {
  word-wrap: break-word;
  word-break:normal;
}
.mention:hover {
    background: #f0f8ff;
}

.ql-mention-list-container {
    z-index: 2;
}

.ql-mention-list-item {
    height: 36px;
    line-height: 36px;
    font-size: 12px;
    padding: 0 16px;
}

.container {
    @media (min-width: 992px) {
        max-width: 900px;
    }

    @media (min-width: 1050px) {
        max-width: 1000px;
    }

    @media (min-width: 1250px) {
        max-width: 1200px;
    }
}

.main_view {
    height: 100%;
    width: 100%;
    //background-color: #F4F8F9;

    .cl_home {
        background-color: $body-bgcolor;
        .cl_home_body {
            padding: 30px;
        }
    }
}

@import 'sass/typography';
@import 'sass/global';

//content
@import 'sass/content/topHeaderSearch';
@import 'sass/content/global';
@import 'sass/content/sidebar';
@import 'sass/content/artist-grids';
@import 'sass/content/image-blocks';
@import 'sass/content/swipe-slider';
@import 'sass/content/artist-circle';
@import 'sass/content/floating-box';
@import 'sass/content/contest';
@import 'sass/content/duets-grid';
@import 'sass/content/chosen-songs-grid';
@import 'sass/content/profile-header';
@import 'sass/content/settings';
@import 'sass/content/modal-dialog';
@import 'sass/content/top-menu';
@import 'sass/content/dashboard-re';
@import 'sass/editor';
@import 'sass/switch';
@import 'sass/input';
@import 'sass/button';

//website
@import 'sass/content/website/header';
@import 'sass/content/website/content';
//@import 'sass/content/website/blog';

@media (max-width: $cl-breakpoint-md - 1) {
    html {
        font-size: 10px;
    }
}

.fa-square {
    left: 23px !important;
}


.col-lg-pull-6 {
    @media (min-width: 768px) {
        right: 50%;
    }
}

.col-lg-push-6 {
    @media (min-width: 768px) {
        left: 50%;
    }
}

button:focus {
    outline: 0;
}

a[disabled] {
    pointer-events: none;
}

a[disabled],
a[disabled] span {
    color: gray !important;
}

body {
    background-color: #FBFBFB;
}

.btn-add {
    background-color: #58C7C9;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 0;
    line-height: 0;
    text-align: center;
    svg {
        width: 18px;
    }
}


.btn-text-add {
    background-color: rgb(88, 199, 201);
    color: rgb(255, 255, 255);
    padding: 0;
    line-height: 0;
    text-align: center;
    display: flex;
    padding: 10px;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    &:hover {
        color: white;
    }
    svg {
        width: 18px;
    }
}

app-message-board-new-topic .ql-snow.ql-toolbar button.qlc-post {
    display: none !important;
}

.check-circle {
    margin: 0;
    cursor: pointer;
    input {
        display: none;
    }
    .round {
        border-radius: 50%;
        border: 1px solid #ADAEB6;
        background-color: #fff;
        display: inline-block;
        width: 30px;
        height: 30px;
        font-size: 16px;
        text-align: center;
        vertical-align: middle;
        line-height: 28px;
    }

    .round-xs {
      width: 20px;
      height: 20px;
      font-size: 10px;
      line-height: 19px;
    }

    .round.active {
        background-color: #58C7C9;
        color: #fff;
        &:before {
            display: inline-block;
            font: normal normal normal 14px/1 FontAwesome;
            font-size: inherit;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: "\f00c";
        }
    }
}

@import 'sass/content/pure-popup';

.featured-list--container {
    background-color: $white;
    border-bottom: 1px solid $light-white;
}

.featured-list--container h2 {
    padding: 14px 15px;
    background-color: $night-main-blue;
    color: $white;
    font-family: $roboto;
    margin-bottom: 0;
    letter-spacing: 1px;
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
}

.featured-list {
    padding: 0 15px;
    margin: 0;
}

.featured-list--item {
    padding: 8px 15px;
    min-height: 64px;
    //max-height: 86px;
    &:hover {
        background: rgb(210, 247, 249) !important;
    }
}

.featured-list--item-poster {
    max-height: none;
    .featured-image--poster {
        width: 100%;
        max-width: 100%;
    }
}

.featured-list--item-action {
    display: flex;
}

.featured-list--item-action a.btn {
    align-self: center;
    border-radius: 50%;
    background: $green;
    border-color: $green;
    font-size: 12px;
    height: 32px;
    width: 32px;
    line-height: 16px;
    text-indent: 1px;
    &:hover {
        background: darken($green, 10%);
        border-color: darken($green, 10%);
    }
}

.featured-list--item a.btn-white {
    background: rgb(255, 255, 255);
    color: rgb(39, 39, 39);
    border: 1px solid rgb(204, 204, 204);
    &:hover {
        background: darken(rgb(255, 255, 255), 10%);
        border-color: darken(rgb(204, 204, 204), 10%);
    }
}

.clickable {
    cursor: pointer;
}

.featured-list--item .align-items-center {
    //display: flex;
}

.featured-list--item:nth-child(2n) {
    background-color: $greyLight;
}

.featured-list--item > div:first-child {
    padding: 4px;
    text-align: center;
}

.featured-list--item > div:nth-child(2) {
    padding-left: 5px;
}

.featured-list--item > div:first-child img {
    max-width: 54px;
    max-height: 54px;
}

.featured-list--item h1 {
    margin-top: 10px;
    font-size: 1em;
    @media (min-width: 768px) {
        font-size: 0.8em;
    }
}

.featured-list--item h1 span {
    color: $light-grey;
    margin-top: 5px;
    display: inline-block;
    font-weight: 400;
}

.featured-list--item img {
    border-radius: 3px;
}

.featured-list--item--poster {
    background-size: cover;
    height: 54px;
    width: 54px;
    border-radius: 3px;
    display: inline-block;
    background-image: url(https://dev-singsnap.imgix.net/placeholders/placeholder_logo.png);
}

.featured-list--item--poster.featured-list--item--poster-full-width {
    width: 100%;
    height: 100%;
    background-position: center;
}

.featured-list--item--poster img {
    opacity: 0;
    visibility: hidden;
}

.featured-list--item--poster a {
    height: 100%;
    display: inline-block;
}

.featured-list--more {
    color: $green;
    font-family: Roboto, sans-serif;
    font-size: 0.8em;
    margin-top: 14px;
    font-weight: bold;
    display: inline-block;
}

.featured-item--body {
    display: flex;
    text-align: left;
}

.featured-list--thread h1 {
    margin-left: 8px;
}

@import 'sass/content/featured-image';

.backdrop-loading {
    background-color: rgba(38, 38, 40, 0.4);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    i {
        animation: spinner .6s linear infinite;
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 3em;
    }
}

.fa-spin-an {
    animation: spinner .6s linear infinite;
}

.ps {
    min-height: 200px;
}

.ss-bold-header {
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
}

.dropdown-orderby {
    &:hover {
        cursor: pointer;
    }
}

.dropdown-orderby .dropdown-item {
    font-size: 10px;
    font-weight: 500;
    background: transparent;
    color: inherit;
    &:hover {
        cursor: pointer;
    }
    &:before {
        content: ' ';
        display: inline-block;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #D3D3D8;
        position: relative;
        right: 8px;
    }
    &:hover:before {
        background-color: #58C7C9;
    }
}

.dropdown-orderby .dropdown-item.selected {
    &:before {
        background-color: #58C7C9;
    }
}

@import 'sass/content/private-messages';

.report-actions-modal.modal.show {
    .modal-dialog {
        /*position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        margin: 0;
        transform: translate(0, -50%);*/
        border-radius: 0;
        .modal-content {
            border-radius: 0;
            border: none;
        }
    }
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            font-size: 12px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            font-weight: 600;
            padding: 10px;
            cursor: pointer;
            text-align: left;
            .fa {
                cursor: pointer;
                color: rgb(208, 208, 208);
                font-size: 12px;
            }

            &:hover .fa {
                color: $light-red;
            }
        }
    }
}

.report-link {
    // position: absolute;
    // top: 0;
    // right: 0;
    cursor: pointer;
    color: rgb(208, 208, 208);
}

.report-link:hover {
    color: rgb(249, 70, 120);
}


.report-actions-modal.modal.show {
    .modal-dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        right: 0;
        margin: 0;
        transform: translate(-50%, -50%);
        border-radius: 0;
        .modal-content {
            border-radius: 0;
            border: none;
            background-color: rgb(251, 251, 251);
        }
    }
    ul {
        margin: 0;
        padding: 0;
        li {
            list-style: none;
            font-size: 12px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            font-weight: 600;
            padding: 10px;
            cursor: pointer;
            .fa {
                cursor: pointer;
                color: rgb(208, 208, 208);
                font-size: 12px;
            }

            &:hover .fa {
                color: $light-red;
            }

            & + li {
                margin-top: 1.6em;
            }
        }
    }

    .btn {
        letter-spacing: 1px;
        & + .btn {
            margin-left: 10px;
        }
    }

    @media all and (max-width: 767px) {
        .modal-dialog {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 0;
            max-width: none;
            transform: none;
            .modal-content {
                height: 100%;
            }
            .modal-body {
                align-items: center;
                display: flex;
                flex-direction: column;
                form {
                    flex: 1 1 auto;
                    width: 100%;
                }
            }
        }
    }

    select, textarea {
        border: 4px solid rgb(218, 240, 240);
    }

    textarea {
        font-size: 12px;
    }
}

.report-type-container {
    position: relative;
    label {
        position: absolute;
        margin: 0;
        top: 22px;
        left: 22px;
        font-size: 12px;
        text-transform: capitalize;
        font-weight: normal;
        color: #A8A8A9;
        letter-spacing: 0;
        margin-right: 4px;
    }
    .report-type-select {
        padding-left: 110px;
    }
}

.report-actions-modal .comment-preview {
    width: 100%;
    padding: 10px;
    .comment-preview-body {
        border: 1px solid #E5E5E5;
        padding: 10px;
        padding-left: 14px;
        border-radius: 3px;
    }
    .comment-preview {
        border: 2px solid $purple;
        border-radius: 0;
        margin-left: 24px;
        padding-left: 14px;
    }
    .comment-body {
        margin: 0;
        text-align: initial;
    }
    .comment-content {
        border-left: 1.5px solid $purple;
        padding-left: 14px;
    }
}

.big-success-icon {
    text-align: center;
    margin: 0 auto;
    border-radius: 50%;
    background-color: rgba(88, 199, 201, 0.1);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    padding-top: 8px;
    margin-bottom: 10px;

    & > span {
        display: block;
        height: 84px;
        width: 84px;
        margin: 0 auto;
        background: rgb(208, 239, 239);
        border-radius: 50%;
        padding-top: 6px;
        & > span {
            display: block;
            background-color: rgb(88, 199, 201);
            border-radius: 50%;
            width: 70px;
            height: 70px;
            margin: 0 auto;
            z-index: 12123129;
            opacity: 1;
            color: rgb(255, 255, 255);
            line-height: 70px;
            font-size: 1.7em;
        }
    }
}

.big-error-icon {
    text-align: center;
    margin: 0 auto;
    border-radius: 50%;
    background-color: rgba(88, 199, 201, 0.1);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    padding-top: 8px;
    margin-bottom: 10px;
    & > span {
        display: block;
        height: 84px;
        width: 84px;
        margin: 0 auto;
        background: rgba(249, 70, 119, 0.2);
        border-radius: 50%;
        padding-top: 6px;
        & > span {
            display: block;
            background-color: rgb(249, 70, 119);
            border-radius: 50%;
            width: 70px;
            height: 70px;
            margin: 0 auto;
            z-index: 12123129;
            opacity: 1;
            color: rgb(255, 255, 255);
            line-height: 70px;
            font-size: 1.7em;
        }
    }
}

/*.dropdown-orderby svg use {
    fill: white;
}
*/
.pill {
    background: rgb(88, 199, 201);
    border-radius: 12px;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    font-family: Roboto, sans-serif;
    padding: 6px 10px;
    line-height: 14px;
    color: #fff;
}

.dropdown-item {
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
    &:hover {

        background-color: rgb(238, 238, 238);
    }
    a {
        color: inherit;
    }
}

.songbook-wrapper,
.ss-r-wrapper {
    .app-icon {
        font-size: 0;
        cursor: pointer;
    }
}

#purePopupWrap {
    .purePopupTitle {
        margin-bottom: 10px;
    }

    .input-row {
        display: flex;

        label {
            width: 25%;
        }
    }
}


.back-title {
    display: flex;
    align-items: center;
    word-break: break-word;
    app-icon-button {
        margin-right: 10px;
    }
}

[ng-reflect-router-link]{
    cursor: pointer;
}

@media (min-width: 1800px) {
    .container-ss {
        max-width: 1600px;
        margin: 0 auto;
    }
}


@media (min-width: 2600px) {

    .container-ss {
        width: 2080px;
        max-width: 2080px;
    }
}

a.disabled {
    pointer-events: none;
    cursor: default;
}


.grayout {
  opacity: 0.6;
  filter: grayscale(100%);
}


.ss-form-input.ss-form-input-textarea {
    height: auto !important;

    textarea {
      border: none;
      resize: none;
      font-size: 12px;
    }
}

.singsnap {
    background-color: $night-main-blue;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
        max-width: 50%;
    }
    img {
        display: inline-block;
        width: 100%;
        max-width: 100%;
        z-index: 100;
    }
}

@import 'sass/darkmode';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
